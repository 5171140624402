// @flow
import { Box, Typography, Button } from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { supportStyles } from './styles';

const Support = () => {
  const classes = supportStyles({});

  return (
    <Box
      bgcolor="#F0F0F0"
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.wrapper}
    >
      <Box className={classes.title}>
        <Typography variant="h5" component="p">
          ECサイトに関するお悩み
          <br />
          なんでもお答えします
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        to="/merchant/contact_us/"
        component={GatsbyLink}
        className={classes.buttonPrimary}
      >
        お気軽にご相談ください
      </Button>
    </Box>
  );
};

export default Support;
