// @flow
import React from 'react';
import loadable from '@loadable/component';
import LazyLoad from 'react-lazyload';
import Layout from '../../components/Layout/Layout';
import Hero from '../../components/Merchant/Hero';
import Support from '../../components/Merchant/Support';
import ApplySection from '../../components/Merchant/ApplySection';
import { SITE_METADATA } from '../../constants';

const Video = loadable(() => import('../../components/Merchant/Video'));

const Navigation = loadable(() =>
  import('../../components/Merchant/Navigation')
);

const Merchant = ({ location }: { location: Object }) => {
  return (
    <Layout
      isMerchant
      SEOProps={{
        title: SITE_METADATA.merchant.title,
        description: SITE_METADATA.merchant.description,
        appendedScripts: [
          {
            async: 'async',
            defer: 'defer',
            src: 'https://h.accesstrade.net/js/nct/lp.min.js',
          },
        ],
      }}
    >
      <LazyLoad height={460} offset={100} once>
        <Hero />
      </LazyLoad>
      <LazyLoad once height={900} offset={500}>
        <Video />
      </LazyLoad>
      <LazyLoad once height={500} offset={100}>
        <Support />
      </LazyLoad>
      <LazyLoad once height={500} offset={100}>
        <Navigation location={location} />
      </LazyLoad>
      <ApplySection />
    </Layout>
  );
};

// $FlowFixMe
export default Merchant;
