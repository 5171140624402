// @flow
import { Box, Typography, Container } from '@material-ui/core';
import React from 'react';
import styles from './Hero.module.scss';

const Hero = () => {
  return (
    <Box
      className={styles.heroContainer}
      position="relative"
      background="linear-gradient(180deg, rgba(#CFC4C4, 0.45) 23.44%, rgba(#D8CFCE, 0.45) 42.71%, rgba(#F4F1EB, 0.45) 100%)"
    >
      <Container maxWidth="md" className={styles.container}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          textAlign="center"
        >
          <article>
            <Typography variant="h1" className={styles.topTitle}>
              <div className={styles.wrapTitle}>
                <p>
                  <span className={styles.bkgTitle}>
                    <span className={styles.title}>
                      決済の悩みをペイディで解決
                      <br />
                      カゴ落ちを防ぎ売上アップ
                    </span>
                  </span>
                </p>
              </div>
            </Typography>
            <Typography variant="body2" component="p" className={styles.info}>
              クレジットカードのいらない決済「ペイディ」なら、
              <br className={styles.newline} />
              ユーザーのニーズに合わせた決済でカゴ落ちを防ぎ、売上アップが見込めます。
            </Typography>
            <svg>
              <defs>
                <filter id="gooey-filter">
                  <feGaussianBlur
                    in="SourceGraphic"
                    result="blur"
                    stdDeviation="3"
                  />
                  <feColorMatrix
                    in="blur"
                    result="colormatrix"
                    type="matrix"
                    values="1 0 0 0 0
                            0 1 0 0 0
                            0 0 1 0 0
                            0 0 0 19 -9"
                  />
                  <feBlend in="SourceGraphic" in2="colormatrix" />
                </filter>
              </defs>
            </svg>
          </article>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
